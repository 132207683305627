import 'react-multi-carousel/lib/styles.css';

import {
  AuditOutlined,
  BorderOutlined,
  CheckSquareOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { Card, Col, Divider, Grid, Radio, Row, Tabs, Typography } from 'antd';
import { graphql, navigate } from 'gatsby';
import { Link, PageProps } from 'gatsby';
import {
  GatsbyImage,
  getImage,
  getSrc,
  ImageDataLike,
} from 'gatsby-plugin-image';
import React from 'react';
import { Helmet } from 'react-helmet';
import Carousel from 'react-multi-carousel';

import Container from '@/components/Container';
import Layout from '@/components/Layout';
import ProductBreadcrumbs from '@/components/ProductBreadcrumbs';
import ProductHeader from '@/components/ProductHeader';
import ProductOptionSelector from '@/components/ProductOptionSelector';
import SEO from '@/components/SEO';
import { Lang } from '@/translations';
import { ProductType } from '@/types';
import { findImageByBase, getRandomInt } from '@/utils';

import * as styles from './product.module.css';

const getModificationKey = (i: ProductType['modifications'][number]) => {
  return [`${i.volume}ml`, i.cap.toLowerCase()].join('-');
};

type ImageDataLikeWithBase = ImageDataLike & { base: string };

const ProductTemplate: React.FC<
  PageProps & {
    data: {
      photos: {
        nodes: ImageDataLikeWithBase[];
      };
      protocol: ImageDataLike;
      declaration: ImageDataLike;
      certificate: ImageDataLike;
    };
    pageContext: {
      categoryName: string;
      categorySlug: string;
      categoryProducts: ProductType[];
      product: ProductType;
      productsCarouselPhotos: ImageDataLikeWithBase[];
    };
  }
> = ({ uri, location, pageContext, data }) => {
  const { productsCarouselPhotos, product, categoryProducts } = pageContext;
  const {
    photos: { nodes: productPhotos },
    protocol,
    declaration,
    certificate,
  } = data;
  const papers: { [key: string]: ImageDataLike } = {
    protocol,
    declaration,
    certificate,
  };
  const selectedModificationIndex =
    product.modifications.findIndex(
      (i) => getModificationKey(i) === location.hash.replace('#', '')
    ) || 0;
  const { lg, xl, xxl } = Grid.useBreakpoint();
  const shouldBeCollapsed = [lg, xl, xxl].every((i) => i !== true);
  const seoImage = getSrc(productPhotos[0]);
  const productLdJson = JSON.stringify({
    '@context': 'http://schema.org/',
    '@type': 'Product',
    name: `${product.name.type || ''} ${product.name.option || ''}`.trim(),
    image: `https://rasept.com${seoImage}`,
    description: product.excerpt || product.description,
    brand: {
      '@type': 'Brand',
      name: 'Rasept',
    },
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: `4.${getRandomInt(76, 99)}`,
      reviewCount: Math.round(
        (Date.now() - Number(new Date(2021, 0))) / 86400000 / 7 +
          getRandomInt(1, 30)
      ),
    },
  });

  // TODO: const AffixComponent = shouldBeCollapsed ? React.Fragment : Affix;

  return (
    <Layout>
      <SEO
        title={`${product.name.type || ''} ${product.name.option || ''}`.trim()}
        description={product.excerpt || product.description}
        uri={uri}
        image={seoImage as string}
      />
      <Helmet>
        <script type="application/ld+json">{productLdJson}</script>
      </Helmet>
      <ProductBreadcrumbs
        categoryName={pageContext.categoryName}
        categorySlug={pageContext.categorySlug}
      />
      <ProductHeader productData={product} />
      <Container>
        <Row
          wrap={!shouldBeCollapsed ? false : undefined}
          gutter={shouldBeCollapsed ? undefined : 48}
        >
          <Col flex={shouldBeCollapsed ? '100%' : '450px'}>
            <Row wrap={false}>
              {(selectedModificationIndex === -1
                ? product.modifications
                : [product.modifications[selectedModificationIndex]]
              ).map((m) => {
                const image = findImageByBase(productPhotos, m.image);

                return image ? (
                  <Col key={m.image} flex={1}>
                    <Card
                      style={{
                        borderColor: '#fff',
                        borderRadius: 0,
                      }}
                      className="mb-4"
                      cover={
                        <GatsbyImage
                          className="mt-4"
                          style={{
                            height:
                              product.modifications.length !== 1 &&
                              selectedModificationIndex === -1
                                ? 400
                                : 600,
                          }}
                          imgStyle={{
                            objectFit: 'contain',
                            // objectPosition: 'bottom left',
                          }}
                          image={image}
                          alt={`${product.name.type || ''} ${
                            product.name.option || ''
                          }`.trim()}
                        />
                      }
                    >
                      <Card.Meta
                        className="text-center"
                        title={`${m.volume}мл (${Lang.ProductCapText[m.cap]})`}
                      />
                    </Card>
                  </Col>
                ) : null;
              })}
            </Row>
          </Col>
          <Col flex="auto">
            <section className="prose prose-lg">
              <blockquote className="lead not-italic mb-6">
                {product.excerpt}
              </blockquote>
            </section>
            {product.modifications.length <= 1 ? null : (
              <>
                <Typography.Paragraph className="mb-1">
                  Тип флакона:
                </Typography.Paragraph>
                <Radio.Group
                  size="large"
                  value={
                    selectedModificationIndex === -1
                      ? undefined
                      : getModificationKey(
                          product.modifications[selectedModificationIndex]
                        )
                  }
                  buttonStyle="solid"
                  className="mb-6 rounded-md"
                >
                  {product.modifications.map((i, idx) => (
                    <Radio.Button
                      key={getModificationKey(i)}
                      value={getModificationKey(i)}
                      onClick={() =>
                        navigate(
                          // TODO https://www.gatsbyjs.com/docs/reference/release-notes/migrating-from-v2-to-v3/#gatsbys-link-component
                          selectedModificationIndex === idx
                            ? window.location.pathname
                            : `#${getModificationKey(i)}`
                        )
                      }
                    >
                      {selectedModificationIndex === -1 ? (
                        <BorderOutlined />
                      ) : getModificationKey(
                          product.modifications[selectedModificationIndex]
                        ) === getModificationKey(i) ? (
                        <CheckSquareOutlined />
                      ) : (
                        <BorderOutlined />
                      )}
                      <span className="ml-2">
                        {i.volume}мл (<i>{Lang.ProductCapText[i.cap]}</i>)
                      </span>
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </>
            )}
            <ProductOptionSelector activeProduct={product} />
            <Tabs size="large" className={styles.Tabs}>
              <Tabs.TabPane
                forceRender
                key="description"
                tab={
                  <>
                    <UnorderedListOutlined />
                    Описание
                  </>
                }
              >
                <article className="prose prose-lg max-w-none">
                  <Typography.Paragraph>
                    {product.description}
                  </Typography.Paragraph>
                  {product.footnote && (
                    <ul className="prose-sm">
                      {product.footnote.map((i, idx) => (
                        <li key={idx}>
                          <Typography.Text key={idx} type="secondary">
                            {i}
                          </Typography.Text>
                        </li>
                      ))}
                    </ul>
                  )}
                  {product.applying && (
                    <>
                      <Divider>
                        <Typography.Title level={4} className="m-0">
                          Способ применения
                        </Typography.Title>
                      </Divider>
                      <Typography.Text>{product.applying}</Typography.Text>
                    </>
                  )}
                  {product.components && (
                    <>
                      <Divider>
                        <Typography.Title level={4} className="m-0">
                          Компоненты
                        </Typography.Title>
                      </Divider>
                      <ul>
                        {product.components.map((i, idx) => (
                          <li key={idx}>
                            <Typography.Text key={idx}>{i}</Typography.Text>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </article>
              </Tabs.TabPane>
              <Tabs.TabPane
                key="certificates"
                tab={
                  <>
                    <AuditOutlined />
                    Документация
                  </>
                }
              >
                {Object.keys(product.certificates).map((title) => {
                  const image = getImage(papers[title.toLowerCase()]);

                  return image ? (
                    <React.Fragment key={title}>
                      <Divider>{Lang.CerificateText[title]}</Divider>
                      <GatsbyImage
                        imgStyle={{
                          objectFit: 'contain',
                          objectPosition: 'center left',
                        }}
                        image={image}
                        alt={title}
                      />
                    </React.Fragment>
                  ) : null;
                })}
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>
        <Divider className="mt-12">
          <Typography.Text className="text-2xl">
            Ещё в этой категории
          </Typography.Text>
        </Divider>
        <Carousel
          ssr
          swipeable={false}
          // draggable={false}
          keyBoardControl={false}
          // deviceType={this.props.deviceType}
          itemClass="px-8"
          sliderClass="m-auto"
          infinite={categoryProducts.length > 4}
          responsive={{
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 4,
              slidesToSlide: 3,
            },
            tablet: {
              breakpoint: { max: 1024, min: 756 },
              items: 3,
              slidesToSlide: 2,
            },
            mobile: {
              breakpoint: { max: 756, min: 0 },
              items: 1,
              slidesToSlide: 1,
            },
          }}
        >
          {categoryProducts.map((i, idx) => {
            const image = findImageByBase(
              productsCarouselPhotos,
              i.modifications[0].image
            );

            return image ? (
              <Link to={`/catalog/${i.slug}`} key={idx}>
                <Card
                  cover={
                    <GatsbyImage
                      image={image}
                      className="mt-2"
                      imgStyle={{
                        objectFit: 'contain',
                      }}
                      alt={`${i.name.type} ${i.name.option}`}
                    />
                  }
                >
                  <Card.Meta title={i.name.type} description={i.name.option} />
                </Card>
              </Link>
            ) : null;
          })}
        </Carousel>
      </Container>
    </Layout>
  );
};

export default ProductTemplate;

export const pageQuery = graphql`
  query ProductAssets(
    $productPhotosGlob: String
    $productProtocolBase: String
    $productDeclarationBase: String
    $productCertificateBase: String
  ) {
    photos: allFile(filter: { base: { glob: $productPhotosGlob } }) {
      nodes {
        id
        base
        childImageSharp {
          gatsbyImageData(height: 600)
        }
      }
    }
    protocol: file(
      sourceInstanceName: { eq: "papers" }
      base: { eq: $productProtocolBase }
    ) {
      id
      childImageSharp {
        gatsbyImageData(height: 600)
      }
    }
    declaration: file(
      sourceInstanceName: { eq: "papers" }
      base: { eq: $productDeclarationBase }
    ) {
      id
      childImageSharp {
        gatsbyImageData(height: 600)
      }
    }
    certificate: file(
      sourceInstanceName: { eq: "papers" }
      base: { eq: $productCertificateBase }
    ) {
      id
      childImageSharp {
        gatsbyImageData(height: 600)
      }
    }
  }
`;
