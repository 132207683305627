import { graphql, useStaticQuery } from 'gatsby';

import { ProductType } from '@/types';

export const useAllProductInfo = (typeName?: string) => {
  const data: {
    allProductJson: {
      nodes: {
        slug: string;
        name: ProductType['name'];
        modifications: {
          image: ProductType['modifications'][number]['image'];
        }[];
      }[];
    };
  } = useStaticQuery(graphql`
    query AllProductInfo {
      allProductJson {
        nodes {
          slug
          modifications {
            image
          }
          name {
            option
            series
            type
          }
        }
      }
    }
  `);

  return typeName
    ? data.allProductJson.nodes.filter((i) => i.name.type === typeName)
    : data.allProductJson.nodes;
};
