import { AppstoreOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import { Link } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

import Container from '@/components/Container';
import { createCategoryDropdownOverlayMenu } from '@/components/Navbar';
import { useGroupedProduction } from '@/hooks/useGroupedProduction';
import { useSiteMetadata } from '@/hooks/useSiteMetadata';

const ProductBreadcrumbs: React.FC<{
  categorySlug: string;
  categoryName: string;
}> = ({ categoryName: productCategoryTitle, categorySlug }) => {
  const { siteUrl } = useSiteMetadata();
  const groupedProductsByCategory = useGroupedProduction();
  const breadcrumbLdJson = JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: productCategoryTitle,
        item: `${siteUrl}/catalog/${categorySlug}`,
      },
    ],
  });

  return (
    <Container className="mt-4">
      <Helmet>
        <script type="application/ld+json">{breadcrumbLdJson}</script>
      </Helmet>
      <Breadcrumb
        style={{
          background: '#fff',
          padding: '8px 12px 8px',
          borderRadius: 4,
          margin: '0 -12px',
        }}
      >
        <Breadcrumb.Item>
          <Link to="/catalog">
            <AppstoreOutlined />
            <span className="ml-1">Каталог</span>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item
          overlay={createCategoryDropdownOverlayMenu(
            groupedProductsByCategory[productCategoryTitle]
          )}
        >
          <UnorderedListOutlined />
          <Link to={`/catalog/${categorySlug}`}>{productCategoryTitle}</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    </Container>
  );
};

export default ProductBreadcrumbs;
