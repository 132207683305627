import { PageHeader, Tag, Typography } from 'antd';
import React from 'react';

import Container from '@/components/Container';
import { ProductType } from '@/types';

const ProductHeader: React.FC<{
  productData: ProductType;
}> = ({ productData }) => (
  <Container>
    <PageHeader
      style={{ padding: '0 0 0.5rem' }}
      title={
        <Typography.Title
          level={1}
          className="whitespace-normal"
          style={{ marginBottom: 0 }}
        >
          {productData.name.type} {productData.name.option}
        </Typography.Title>
      }
      // TODO
      // tags={
      //   productData.name.series ? <Tag>{productData.name.series}</Tag> : null
      // }
    />
    <div className="pb-8">
      {productData.advantages.map((i) => (
        <Tag key={i} color="purple" className="mb-1">
          {i}
        </Tag>
      ))}
    </div>
  </Container>
);

export default ProductHeader;
