import { Cerificate, ProductCap } from '../types';

type Translations = { [key: string]: string };

export const Lang = {
  ProductCapText: {
    [ProductCap.DIN24]: 'DIN 24',
    [ProductCap.SNAP_ON]: 'Крышка',
    [ProductCap.FLIP_TOP]: 'Флип-топ',
    [ProductCap.DISPENSER]: 'Распылитель',
  } as Translations,
  CerificateText: {
    [Cerificate.DECLARATION]: 'Декларация о соответствии',
    [Cerificate.PROTOCOL]: 'Протокол испытаний',
    [Cerificate.CERTIFICATE]: 'Свидетельство о государственной регистрации',
  } as Translations,
};
