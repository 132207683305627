import { Divider, List, Popover, Typography } from 'antd';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

import { useAllProductInfo } from '@/hooks/useAllProductInfo';
import { ProductType } from '@/types';
import { findImageByBase } from '@/utils';

import {
  ProductOptionSelector as ProductOptionSelectorClassName,
  ProductOptionSelectorText,
} from './ProductOptionSelector.module.css';

const ProductOptionSelector: React.FC<{
  activeProduct: ProductType;
}> = ({ activeProduct }) => {
  const {
    smallProductPhotos: { nodes: smallProductPhotos },
    productPhotos: { nodes: productPhotos },
  } = useStaticQuery(graphql`
    {
      smallProductPhotos: allFile(
        filter: { sourceInstanceName: { eq: "products" } }
      ) {
        nodes {
          id
          base
          childImageSharp {
            gatsbyImageData(
              width: 75
              height: 100
              transformOptions: { fit: CONTAIN }
            )
          }
        }
      }
      productPhotos: allFile(
        filter: { sourceInstanceName: { eq: "products" } }
      ) {
        nodes {
          id
          base
          childImageSharp {
            gatsbyImageData(height: 240)
          }
        }
      }
    }
  `);
  const exactGroup = useAllProductInfo(activeProduct.name.type).map((i) => ({
    ...i,
    smallImage: findImageByBase(smallProductPhotos, i.modifications[0].image),
    image: findImageByBase(productPhotos, i.modifications[0].image),
  }));

  return exactGroup.length === 1 ? null : (
    <div>
      <Typography.Text className="block mb-2">
        <b>{activeProduct.name.type}:</b> {activeProduct.name.option}
      </Typography.Text>
      <List
        // size="small"
        grid={{ gutter: 8 }}
        itemLayout="horizontal"
        dataSource={exactGroup}
        renderItem={(i: any) => (
          <List.Item key={i.slug}>
            <Link to={`/catalog/${i.slug}`}>
              <Popover
                overlayClassName={ProductOptionSelectorClassName}
                overlayStyle={{ width: 180 + 16 }}
                content={
                  <div>
                    <GatsbyImage
                      image={i.image}
                      imgStyle={{
                        objectFit: 'contain',
                      }}
                      alt={`${i.name.type} ${i.name.option}`}
                    />
                    <Divider className="mt-3 mb-0" />
                    <div className={ProductOptionSelectorText}>
                      {i.name.option}
                    </div>
                  </div>
                }
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    // width: 75 + 0,
                    // height: 120 + 16,
                    backgroundColor: 'white',
                    padding: '4px 0',
                    borderRadius: 4,
                    cursor:
                      activeProduct.slug === i.slug ? 'default' : undefined,
                    border: '2px solid transparent',
                    borderColor:
                      activeProduct.slug === i.slug
                        ? 'var(--ant-error-color)'
                        : 'transparent',
                  }}
                >
                  <GatsbyImage
                    image={i.smallImage}
                    style={{ pointerEvents: 'none' }} // Иначе, не работает Popover
                    alt={`${i.name.type} ${i.name.option}`}
                  />
                </div>
              </Popover>
            </Link>
          </List.Item>
        )}
      />
    </div>
  );
};

export default ProductOptionSelector;
