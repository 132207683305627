/* eslint-disable no-unused-vars */
import { Component } from '../data/enums';

export enum ProductCap {
  DIN24 = 'DIN24',
  SNAP_ON = 'SNAP_ON',
  FLIP_TOP = 'FLIP_TOP',
  DISPENSER = 'DISPENSER',
}

export enum Cerificate {
  DECLARATION = 'DECLARATION',
  PROTOCOL = 'PROTOCOL',
  CERTIFICATE = 'CERTIFICATE',
}

type ContentType = {
  title?: string;
  text?: string;
  content?: any[];
};

export type ArticlesType = {
  title: string;
  imageName: string;
  content: ContentType[];
  slug: string;
};

export type ProductType = {
  name: {
    type: string;
    option: string;
    series?: string;
  };
  advantages: string[];
  excerpt: string;
  description: string;
  modifications: {
    cap: ProductCap;
    volume: number;
    weight: number;
    image: any;
  }[];
  components: Component[] | null;
  footnote: string[] | null;
  applying: string | null;
  certificates: { [key: string]: string }; // keyof?
  slug: string;
};
